export default class access {

  enter = {};
  read = {};
  delete = {};  

  constructor(ud) {

    this.ud = ud;

    /**
     * ud: user logged in
     * ud.type : i.e. "admin, editor"
     * ud.team : i.e. "vinnlab, icampus"
     */     

    var type = ud ? ud.type : null;

    this.enter = {

      admin: type ? true : false

    }

    this.read = {

      poi: this.readPoi(type) || false,

      pano: this.readPano(type) || false,

      testfield: this.readTestfield(type) || false,

      testbed: this.readTestbed(type) || false,

      user: this.readUser(type) || false,

      tour: this.readTour(type) || false,

      resp: this.readResp(type) || false,

      device: this.readDevice(type) || false,

      usecase: this.readUsecase(type) || false,

      link: this.readLink(type) || false,

      element: this.readElement(type) || false

    }

    this.add = {

      poi: this.addPoi(type) || false,

      pano: this.addPano(type) || false,

      user: this.addUser(type) || false,

      tour: this.addTour(type) || false,

      resp: this.addResp(type) || false,

      device: this.addDevice(type) || false,

      testfield: this.addTestfield(type) || false,

      usecase: this.addUsecase(type) || false,

      element: this.addElement(type) || false,

    }


    this.delete = {

      poi: this.deletePoi(type) || false,

      pano: this.deletePano(type) || false
    
    }

  }    

  /**
   * 
   * @param {*} itemObj: item object
   * @param {*} type : type string
   * @param {*} user : user object
   */
  readitem ( itemObj, type, user ) {
    
    switch (type) {

      case 'poi':

        if(user.type == 'admin') {

          return true;

        } else {

          return itemObj.team_name === user.team ? true : false;

        }      

      case 'pano':

        if(user.type == 'admin') {

          return true;

        } else {

          return itemObj.team_name === user.team ? true : false;

        }      

      case 'tour':

        if(user.type == 'admin') {

          return true;

        } else {

          return itemObj.team_name === user.team ? true : false;

        }      

      case 'category':

        if(user.type == 'admin') {

          return true;

        } else {

          //console.log(itemObj.team_name);        
          
          return itemObj.team_name !== 'iCampus' ? true : false;

        }      
          

      default:
        break;

    }    
    
  }
  
  readPoi( e ) {

    return ['admin','editor', 'innohub'].includes(e) ? true : false        
  
  }  

  readPano( e ) {

    return ['admin', 'innohub'].includes(e) ? true : false 

  }      
  
  readTestfield( e ) {

    return e == "admin" ? true : false

  }

  readUser( e ) {

    return e == "admin" ? true : false

  }  

  readTour( e ) {

    return ['admin', 'innohub'].includes(e) ? true : false

  }    

  readResp( e ) {

    return e == "admin" ? true : false

  }  

  readTestbed( e ) {

    return e == "admin" ? true : false

  }  

  readUsecase( e ) {

    return e == "admin" ? true : false

  }  

  readDevice( e ) {

    return e == "admin" ? true : false

  }  

  readLink( e ) {

    //return e == "admin" ? true : false
    return ['admin', 'innohub'].includes(e) ? true : false 

  }    

  readElement( e ) {

    return e == "admin" ? true : false

  }    

  addPoi( e ) {

    return ['admin','editor','innohub'].includes(e) ? true : false 

  }  

  addPano( e ) {

    return ['admin','innohub'].includes(e) ? true : false 

  }    

  addUser( e ) {

    return e == "admin" ? true : false

  }   

  addTour( e ) {

    return ['admin','innohub'].includes(e) ? true : false

  }     

  addResp( e ) {

    return e == "admin" ? true : false

  }     

  addDevice( e ) {

    return e == "admin" ? true : false

  }    

  addTestfield( e ) {

    return e == "admin" ? true : false

  }      

  addUsecase( e ) {

    return e == "admin" ? true : false

  }      

  addElement( e ) {

    return e == "admin" ? true : false

  }    

  deletePoi( e ) {    

    return e ? true : false        

  }  

  deletePano( e ) {

    return e ? true : false        

  }  

}
