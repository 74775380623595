<template>
<div>
      <v-row justify="center">

        <v-dialog
          v-model="dialog"
          width="600"
          content-class="helpdialog"
          scrollable  
          :retain-focus="false"        
        >        
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
            @click="dialog = !dialog"
            hidden
          >
            Hidden buttom
          </v-btn>
        </template>
        <v-card class="helpwrap" flat>      
          <v-card-text>
          <h4 v-if="content.kat == 'error'" v-html="'Es ist ein Fehler aufgetreten'"></h4>
          <div v-html="content.name"></div>
          <div v-html="infosource[content.name]"></div>                              
          </v-card-text>          
          <v-card-actions class="cardaction">
            <v-spacer></v-spacer>
            <v-btn       
              color="#f7f7f7"   
              class="helpclose m-2"
              @click="dialog = false"
            >
            schließen
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>

      </v-row>

  </div>
</template>

<script>

//import WavePlayer from './WavePlayer.vue';
import infotext from '../../source/info.json';

export default {
    name: 'InfoDialog',
    props: {
      content: {
          type: Object
      } 
    },
    components: {
      
    },
    data () {
      return {
        dialog: false,
        notifications: false,        
        infosource: null
      }
    },
    watch: {
      overlay(val) {
        if (val) {
          document.addEventListener('mousedown', this.mousedownHandler)
        } else {
          document.removeEventListener('mousedown', this.mousedownHandler)
        }
      }
    },    
    methods:{
      openinfo () {
          console.log("openinfo!");
          console.log("overlay? " + this.overlay);
          //this.overlay = !this.overlay;
          this.dialog = !this.dialog;
          //this.overlay ? !this.overlay : true;
          //this.$refs.btnhelp.click();
      },
      clickout () {
        console.log("Click outside!");
        //this.overlay = false
      },
      onKeydown(e) {
        if (this.overlay) {
            if (e.keyCode == 27) {
                this.overlay = false;
            } 
        }
      },
      mousedownHandler(e) {
        if (e.target.classList.contains('v-overlay__scrim')) this.overlay = false
      }            
    },
    created() {        
        document.addEventListener('keydown', this.onKeydown);
        this.infosource = infotext;
    },
    beforeDestroy() {
        document.removeEventListener('keydown',this.onKeydown)
    },    
}

</script>

<style>
.helpclose {
  float:right;    
  margin:6px 0;
}
.helpdialog {  
  background-color: #ffffff;
}
</style>

<style scoped>
.v-overlay {
  transition-duration: 0s, 1ms;  
}
.cardaction {
  padding:8;
  justify-content: end;
}
.helpclose {
    
}
.v-overlay--active {
    top: 0;
    right:0;
    bottom: 0px;
}
.v-btn--contained {
  height:32px !important;
  padding:0 8px !important;
}
.helpwrap {
  position:relative;
  margin:0 auto;
  max-width:800px;  
}
.helpwrap .v-card__text {
  padding-top:20px;  
}

</style>
