<template>
<div>
      <v-row justify="center">

        <v-dialog
          v-model="dialog"
          width="400"
          content-class="helpdialog"
          scrollable        
          :retain-focus="false"  
        >        
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
            @click="dialog = !dialog"
            hidden
          >
            Hidden buttom
          </v-btn>
        </template>
        <v-card class="helpwrap" flat>      
          <v-card-text>
            <div
            style="padding:20px;text-align:center;"
            >Wollen Sie uns schon verlassen??  </div>
            <v-btn                    						
						class="btn btn--red"
						@click="exitconfirm(true)"
            >Ja</v-btn>        
            <v-btn                    						
						class="btn btn--red"
						@click="exitconfirm(false)"
            >Nein, ich möchte weiter testen</v-btn>        
          </v-card-text>          
        </v-card>
        </v-dialog>

      </v-row>

  </div>
</template>

<script>

//import WavePlayer from './WavePlayer.vue';
import helptext from '../../source/help.json';

export default {
    name: 'ExitDialog',
    props: {
      content: {
          type: String
      } 
    },
    components: {
      
    },
    data () {
      return {
        dialog: false,
        notifications: false,        
        helpsource: null,
        confirmed: false
      }
    },
    watch: {
      overlay(val) {
        if (val) {
          document.addEventListener('mousedown', this.mousedownHandler)
        } else {
          document.removeEventListener('mousedown', this.mousedownHandler)
        }
      }
    },    
    methods:{
      openexit () {
          this.dialog = !this.dialog;
      },
      exitconfirm(bool) {
        if(bool) {
          console.log("bool true");
          this.$emit('closeApp', true);
          this.dialog = false;
        } else {          
          this.dialog = false;
        }
      },
      clickout () {
        //this.overlay = false
      },
      onKeydown(e) {
        if (this.overlay) {
            if (e.keyCode == 27) {
                this.overlay = false;
            } 
        }
      },
      mousedownHandler(e) {
        if (e.target.classList.contains('v-overlay__scrim')) this.overlay = false
      }            
    },
    created() {        
        document.addEventListener('keydown', this.onKeydown);
        this.helpsource = helptext;
    },
    beforeDestroy() {
        document.removeEventListener('keydown',this.onKeydown)
    },    
}

</script>

<style>
.helpclose {
  float:right;    
  margin:6px 0;
}
.helpdialog {  
  background-color: #ffffff;
}
</style>

<style scoped>
.v-overlay {
  transition-duration: 0s, 1ms;  
}
.cardaction {
  padding:8;
  justify-content: end;
}
.v-overlay--active {
    top: 0;
    right:0;
    bottom: 0px;
}
.v-btn--contained {
  height:32px !important;
  padding:0 8px !important;
}
.helpwrap {
  position:relative;
  margin:0 auto;
  max-width:800px;  
}
.helpwrap .v-card__text {
  padding-top:20px;  
}

</style>
