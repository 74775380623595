<template>
  <div ref="usecaseinfo" class="wrap usecaseinfo" :style="'width:'+sizeWidth+'%'">
    <div ref="wrapinfo" id="innerinfo">
      <v-row>
        <v-col cols="12" sm="12" class="timelinefirst">
          <div class="timestephead">
            <div class="triangle-down"></div>                  
            <div style="display:block;margin-bottom:30px;">
              <img src="https://icampus.th-wildau.de/vr/graf/wisskomm/NLP_TitelIllustration.svg" height="80px" style="float:left;margin-right:20px;"/>
              <p><a href="https://wissenstransfer.innohub13.de/nlp/" target="_blank">WISSENSTRANSFER: Wie Computer lernen, die menschliche Sprache zu verstehen</a></p>
            </div>
            <h6 style="margin-top:6px;">Prozessverlauf: Was passiert hier?</h6>
            
          </div>          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >                      
            <div class="timestep">
              <div class="ptitle">Audioquelle</div>
            </div>
            <div class="stepcontent" ref="nlp_audiosource">
              Zunächst wird der Schall der Umgebung, welcher aus einer Mixtur aus der zu erkennenden Sprache und uninteressanten Nebengeräuschen besteht, von ein oder mehreren Mikrofonen in ein elektrisches und anschließend durch einen Analog-Digital-Umsetzer (Soundkarte) in ein digitales Audiosignal umgewandelt.
Diese Audiodaten können nun in den nächsten Schritten weiter verarbeitet werden. 
Entscheidend für die Qualität sind die Eigenschaften und Platzierung der Mikrofone, die Raumakustik die Anwesenheit von Störquellen und weitere Faktoren.
In praktischen Anwendungen können jedoch viele Faktoren nicht gezielt beeinflusst werden.<br><br>
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Signalvorbereitung</div>                    
            </div>
            <div class="stepcontent" ref="nlp_signal">
              Die Vorverarbeitung liegt im Fokus dieses Testfeldes.
Durch sie findet eine Aufbereitung des Audiosignals statt, um Nebengeräusche zu kompensieren.
Es existiert eine Vielzahl an unterschiedlichen Verfahren, von denen manche in diesem Testfeld ausprobiert werden können.
Oftmals sind dafür weitere Informationen über die Bedingungen der Audioaufnahme wie z.B. ein Rauschprofil oder die Mikrofongeometrie nötig.<br><br>
<!--
              <v-btn fab depressed small class="helpbtn" @click="help('processingmethod')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>              
              -->
            </div>            
        </v-col>
      </v-row>  

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Spracherkennung</div>                  
            </div>
            <div class="stepcontent" ref="nlp_recognition">
              Bei der automatischen Spracherkennung (ASR - Automatic Speech Recognition) wird die Sprache im Audiosignal transkribiert und in Textform ausgegeben.
Möglich wird dies durch die Verwendung von Modellen, welche anhand von großen Datensammlungen trainiert wurden.
Ein solcher Korpus umfasst unzählige Stunden an Audiomaterial von gesprochenen Texten sowie die Texte an sich.
Darüber hinaus sind noch weitere Informationen zur verwendeten Sprache vonnöten, wie etwa die Zusammensetzung von Lauten bzw. Phonemen aller möglichen Wörter.
Die Leistungsfähigkeit der Spracherkennung hängt nicht nur allein von der Software ab, sondern auch maßgeblich von den eingesetzten Modellen.<br><br>            
<!--
              <v-btn class="helpbtn" small fab depressed @click="help('modus')">
                <v-icon size="18" >mdi-information-variant</v-icon> 
              </v-btn>
-->
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Natural Language Understanding</div>                  
            </div>
            <div class="stepcontent">
Um die Bedeutung des extrahierten Textes zu verstehen, wird beim NLU eine linguistische Textauswertung durchgeführt.
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Dialoglogik</div>                  
            </div>
            <div class="stepcontent">
              Basierend auf den Informationen des NLU kann nun entsprechend reagiert werden, indem Aktionen ausgelöst oder Informationen abgerufen werden.              
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Natural Language Generation</div>                  
            </div>
            <div class="stepcontent">
              Mittels der NLG werden die Informationen für die Antwort an den Nutzer in einem natürlichsprachigen Text formuliert.            
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Sprachsynthese</div>                  
            </div>
            <div class="stepcontent">
              Die Sprachsynthese generiert wiederum durch das Vorlesen mit einer synthetischen Stimme ein Audiosignal.            
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div class="ptitle">Audioausgabe</div>                  
            </div>
            <div class="stepcontent">
              Am Ende wird das resultierende Audiosignal über ein Audioausgabegerät (Digital-Analog-Umsetzer (Soundkarte) + Lautsprecher) wiedergegeben und somit dem Nutzer mittels Schall präsentiert.
            </div>
        </v-col>
      </v-row>

      <HelpDialog ref="help" :content="helpcontent" class="mr-4"></HelpDialog>

    </div>

  </div>
</template>

<script>

  import HelpDialog from '../monitor/HelpDialog';  

  export default {
    name: 'info_nlp',

    components: {
      HelpDialog
    },
    data () {
      return {
        helpcontent: null
      }
    },
    props: {
      infocontent: {
          type: String
      },
      sizeWidth: {
          type: Number
      }
    },    
    methods:{         
      help(name) {        
        this.helpcontent = name;        
        this.$refs.help.openhelp();
      },
      scrollMeTo(refName) {

        var stepcontents = this.$el.querySelectorAll('.stepcontent');              
        var index = 0, length = stepcontents.length;
        for ( ; index < length; index++) {
          stepcontents[index].style.backgroundColor = "transparent";          
        }

        var element = this.$refs[refName];
        element.style.backgroundColor = '#f9f9f9';
        var wrap = this.$refs['usecaseinfo'];
        var wrapinfo = this.$refs['wrapinfo'];

        var wraptop = wrapinfo.getBoundingClientRect().top;        
        var elementtop = element.getBoundingClientRect().top;        
        var scroll = Math.abs( wraptop - elementtop ) - 48;
        wrap.scrollTo(0, scroll);
      }
    },
    mounted() {        
        //console.log("on mounted");            
    } 
  }
</script>

<style scoped>
.wrap {
  position:fixed;
  top:0;
  bottom:0;
  padding-top:48px;  
  overflow-y: scroll;  
  background-color: #d6e5f1;
}

.innerinfo {
  position:relative;
}
.wrap .row {
  margin-top:0;
  margin-left:0;
  margin-right:0;
}

.timeline,
.timelinefirst {
  background: linear-gradient( 90deg, transparent, transparent 14px, #f5b041 14px, #f5b041 22px, transparent 22px);
  padding:12px 12px 12px 36px;
  font-size:.88rem;
}
.timelinefirst {
  padding:12px 12px 12px 36px;
  font-size:.88rem;
}
.timestep,
.timestephead {
  margin:0;
  padding:10px 0;
  position:sticky;  
  top:0;
  background-color: #d6e5f1;  
  border-bottom:1px solid #ccc;
}
.triangle-down {
  position:absolute;
  left:-29px;
  top:6px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 22px solid #f5b041;  
}
.timestep::before {
  content: " ";
  width:22px;
  height:22px;
  background-color: #f5b041;
  border-radius: 14px;
  display:inline-block;
  margin-right:10px;
  margin-left:-29px;
}
.timestep .ptitle {
  font-size:1rem;
  color:#000;
  font-weight: 500;
  display:block;
  margin-left:6px;
  margin-top:-28px;
}
.stepcontent {
  padding:10px 16px 10px 6px;
}
.timestephead h6 {
  font-size:1rem;
  color:#000;
  font-weight: 500;
  display:block;
  margin-left:8px;
  margin-top:-28px;
}

</style>
